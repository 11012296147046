import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Button, Space, Result as Res } from 'antd-mobile'
const Result = () => {
	const [t, i18n] = useTranslation()
	return (
		<div className="paybox" style={{padding:30,paddingTop:40}}>
            <Res
            status='success'
            title={t('支付成功')}
            description={t('您已成功支付订单')}
            />
            <Button onClick={()=>{
              window.close();
            }} block color='primary' size='large'>
          {t("确定")}
        </Button>
		</div>
	)
}

export default Result;