import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Button, Space, Toast } from 'antd-mobile'
import Ajax from "./Ajax";
import { Result as Res } from 'antd-mobile'
import { useLocation } from 'react-router-dom';
const PayPal = (props) => {
    const location = useLocation(); // 获取URL信息
   
    useEffect(() => {
        const pathArr=location.pathname.split("/");
        const order_sn=pathArr[pathArr.length-1]
        console.log("获取订单详情")
        payOrder(order_sn)
	}, [])
    const payOrder=(order_sn)=>{
        let load=Toast.show({
            icon: 'loading'
          })
          Ajax.hhtc_request("home/capaturePayOrder",{order_sn}).then(res => {
            load.close();
            if(res.code==1)
            {
                window.location.href="http://pay.floatingenergy.ltd/#/result"
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
        })
    }
	return (
		<div className="paybox" style={{padding:30,paddingTop:40}}>
           
		</div>
	)
}

export default PayPal;