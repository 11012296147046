import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Button, Space, Toast,Popup} from 'antd-mobile'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckOutFrom";
import Ajax from "./Ajax";
import './stripe.css';
import { useLocation } from 'react-router-dom';
const stripePromise = loadStripe("pk_live_51NqeaVDHp3REMWXQKKhyWjjxt7EDyVaEtiAQQIU6hViksFNjWbe4LAIdZCaauEpyQ0wc0gt9fyRX6Yp3AsOzjBOm00QlHNGCzR");
const PayPage = (props) => {
    const pathInfo=useLocation();
	const [typeList] = useState([
        { icon: require('./imgs/stripe.png'), name: 'Stripe',id:1 },
        { icon: require('./imgs/alipay.png'), name: 'Alipay',id:2 },
		{ icon: require('./imgs/paypal.png'), name: 'PayPal',id:3 },
		
		
	])
	const [tip] = useState([
		{ name: '支付5小费', id: 1 },
		{ name: '支付10小费', id: 2 },
		{ name: '支付15小费', id: 3 },
	])

	
	const [obj, setObj] = useState({
		money: '',
		remark: '',
		paytype: 0,
		tip: '',
	})
    const [t, i18n] = useTranslation()
    const [isZhCN,setIsZhCN]=useState(true);//是否为中文
	const [canEditMoney,setCanEditMoney]=useState(true);//是否允许设置金额
	const [money,setMoney]=useState("");//支付金额
	const [merchant_id,setMerchantId]=useState(0);//商家ID
	const [merchantName,setMerchantName]=useState("");//商家名称
	const [paymentFee,setPaymentFee]=useState(0.00);//支付费率
    const [payFeeMoney,setPayFeeMoney]=useState(0);//支付服务费
	const [merchantRemarks,setMerchantRemarks]=useState("");//商家备注
	const [xfRate,setXfRate]=useState(0);//小费比例
    const [xfPrice,setXfPrice]=useState(0);//小费金额
    const [orderPrice,setOrderPrice]=useState(0);//订单总额

    const [payType,setPayType]=useState(1);//支付方式 1stripe 2支付宝 3PayPal
	const [userRemarks,setUserRemarks]=useState("");//用户备注

    const [clientSecret,setClientSecret]=useState("")
	const [visible,setVisible]=useState(false)
	
	useEffect((e) => {
		document.title = '付款';
		const urlParams = new URLSearchParams(window.location.search);
        console.log()
        var pathArr=pathInfo.pathname.split("/");
        console.log(pathArr)
        if(pathArr.length<3)
        {
            Toast.show({
                icon: 'fail',
                content: t("非法请求"),
              })
              return;
        }
        console.log(urlParams.get("merchant_id"))
		setMerchantId(pathArr[2]);
        if(pathArr.length>3)
        {
            setMoney(pathArr[3]);
			setCanEditMoney(false);//不允许修改金额
        }
        if(pathArr.length>4)
        {
            setMerchantRemarks(pathArr[4]);
        }else{
            setMerchantRemarks("");
        }
	
        setIsZhCN(localStorage.getItem('lang')=="zh-CN")
        getMerchantInfo();
	}, [merchant_id])
    useEffect(() => {
		//计算订单总额
        var m=money;//支付金额
        var xf=xfRate*5*m/100;
        setXfPrice(xf)
        var xj=parseFloat(m)+xf;
        //支付费率
        var payFee=parseFloat((xj*paymentFee/100).toFixed(2)) || 0;
        setPayFeeMoney(payFee)
        console.log('支付金额',money)
        console.log("小费",xf)
        console.log("小计",xj)
        console.log("费率:",payFee)
        console.log("合计:",payFee+xj)
        setOrderPrice(parseFloat((payFee+xj).toFixed(2)));
        
	}, [money,xfRate,paymentFee])
    const changeLang=(locale)=>{
        // const locale = isCN ? "en-US" : "zh-CN"
        localStorage.setItem('lang', locale)
        i18n.changeLanguage(locale)
        setIsZhCN(locale=="zh-CN")
    }
    const getMerchantInfo=()=>{
        let load=Toast.show({
            icon: 'loading'
          })
        Ajax.hhtc_request("home/getPaymenFee",{merchant_id}).then(res => {
            load.close();
            if(res.code==1)
            {
                setMerchantName(res.data.name)
                setPaymentFee(res.data.fee)
                
            }
        })
    }

    const pay=()=>{
        if(money<=0)
        {
            Toast.show({
                icon: 'fail',
                content: t("请输入支付金额"),
              })
              return;
        }
        var req={
            merchant_id,
            pay_type:payType,
            xf:xfPrice,
            money,
            merchant_remarks:merchantRemarks,
            user_remarks:userRemarks
        }
        console.log(req)
        let load=Toast.show({
            icon: 'loading'
          })
        Ajax.hhtc_request("home/createOrder",req).then(res => {
            load.close();
            if(res.code==1)
            {
                if(payType<3)
                {
                    setClientSecret(res.data.client_secret)
                    setVisible(true)
                }else{
                    window.location.href=res.data.links
                }
            }else{
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                  })
            }
        })
    }
    const stripePay=(clientSecret)=>{
       
        // const stripe = useStripe();
        // const elements = useElements();
        // stripe.retrievePaymentIntent(clientSecret).then(res=>{
        //     console.log(res)
        // })
    }


	return (
		<div className="paybox">
            <div className="langBtn">
                <div onClick={()=>{changeLang("zh-CN")}} className={"langItem "+(isZhCN?"langActive":"")}>中文</div>
                <div onClick={()=>{changeLang("en-US")}}  className={"langItem "+(isZhCN?"":"langActive")}>English</div>
            </div>
			<p className="user">{t("支付给",{merchantName:merchantName})}</p>
			<div className="flexCenter mbox">
				<h1>£</h1>
				<input value={money} onChange={(e)=>{
                   setMoney(e.target.value)
                   
                }} readOnly={!canEditMoney} type={"number"} placeholder={t("请输入金额")} />	
			</div>
            <div className="remarkbox flexCenter padd10">
				<p style={{flex:1}}>{t("服务费")}</p>
				<p  className="rowFlex" style={{textAlign:"right"}}>£ {payFeeMoney}</p>
			</div>
			
			<div className="remarkbox flexCenter padd15">
				<p>{t("备注")}</p>
				<input onChange={(e)=>{
                    setUserRemarks(e.target.value)
                }}  className="rowFlex" placeholder={t("请输入")} />
			</div>
            {merchantRemarks!="" && 
			<div className="remarkbox flexCenter padd15" >
				<span>{merchantRemarks}</span>
			</div>
			}
			{typeList.map((item, index) => (
				<div onClick={()=>{
					setObj({
						...obj,
						paytype: index,
					})
                    setPayType(item.id)
				}} className="flexCenter borderb1ef typebox" key={String(index)}>
					<img alt='' src={item.icon} className="icon" />
					<p className="rowFlex">{item.name}</p>
					<span className={`quan ${obj.paytype == index ? 'active' : ''}`}></span>
				</div>
			))}
			{/* 结算栏 */}
			<div className="fixed">
				<div className="flex tipbox">
					{tip.map((item, index) => (
						<div onClick={() => {
                            
							setObj({
								...obj,
								tip: obj.tip == item.id?"":item.id,
							})
                            if(item.id==xfRate)
                            {
                                setXfRate(0)
                            }else{
                                setXfRate(item.id)
                            }

						}} className="rowFlex flexCenter" key={String(index)}>
							<div className={`flexAllCenter kk ${obj.tip == item.id?'active':''}`}>
								{obj.tip == item.id && <img alt='' src={require('./imgs/gou.png')} className="gou" />}
							</div>
							<p>{t(item.name)}</p>
						</div>
					))}
				</div>
                
				<p onClick={()=>{
                    pay();
                    // Toast.show({
                    //     icon: 'success',
                    //     content: '保存成功',
                    //   })
                }} className="btn">{t("支付")} £ {orderPrice || 0}</p>
               {/* {clientSecret && (
                    <Elements options={{
                        clientSecret
                    }} stripe={stripePromise}>
                    <CheckoutForm />
                    </Elements>
                )} */}
			</div>

            <Popup
              visible={visible}
              onMaskClick={() => {
                setVisible(false)
                setClientSecret("")
              }}
              bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '40vh',
              }}
            >
              {clientSecret && (
                    <Elements options={{
                        clientSecret
                    }} stripe={stripePromise}>
                    <CheckoutForm />
                    </Elements>
                )}
            </Popup>
		</div>
	)
}

export default PayPage;