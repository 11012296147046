
export default class Ajax {
    static hhtc_request(url, data) {
        const requrl = 'https://www.floatingenergy.ltd/api/' + url;
        // const requrl = 'http://192.168.1.168:8084/' + url;  //测试服
        var init = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;',
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        }
        return fetch(requrl, init).then(
            (response) => {
                return response.json()
            }
        ).then(
            (data) => {
                console.log("执行一次网络请求:请求地址=》", requrl, "请求数据", init, "返回结111果=>", data)
               
                return data;
            }
        ).catch(
            (err) => {
                console.log("执行一次网络请求:请求地址=》", requrl, "请求数据", init, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )
    }
}