import React, { useEffect, useState } from "react";
import PayPage from "./PayPage";
import Result from "./Result";
import PayPal from "./PayPal";
import {
	Route,
	Routes,
	HashRouter as Router,
	Navigate,
} from "react-router-dom";
const App = (props,p1) => {
	
	return (
		<Router>
			<Routes>
				<Route path="/pay/:merchant_id/:money/:remarks" element={<PayPage />} />
				<Route path="/pay/:merchant_id" element={<PayPage />} />
				<Route path="/pay/:merchant_id/:money" element={<PayPage />} />
				<Route path="/result" element={<Result />} />
				<Route path="/paypal/:order_sn" element={<PayPal />} />
				{/* 默认访问----重定向至首页 1 2222 */}
				<Route path="" element={<Navigate to="/result" />} />
			</Routes>
		</Router>
		// <PayPage />
	)
}

export default App;