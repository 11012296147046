import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './en-US.json'
import zh from './zh-CN.json'

const resources = {
  'en-US': {
    translation: enUS,
  },
  'zh-CN': {
    translation: zh,
  },
}

let localLang = localStorage.getItem('lang')
if (!localLang) {
  const browserLanguage = navigator.language || navigator.userLanguage
  localLang = browserLanguage === 'zh-CN' ? 'zh-CN' : 'en-US'
}
console.log(localLang)
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localLang,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n